.header-menu {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.fixed {
    position: fixed;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }

  &.white {
    color: #fff;

    .menu {
      .menu-item {
        color: #fff;
        &.active, &:hover {
          span {
            &::after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  &.black {
    color: #000;
    background-color: #fff;

    .menu {
      .menu-item {
        color: #000;
        &.active {
          span {
            &::after {
              background-color: #000;
            }
          }
        }
      }
    }

    .logo {
      .blue {
        display: inline;
      }
      .white {
        display: none;
      }
    }
  }
  
  .logo {
    width: 212px;
    text-align: right;
    img {
      width: 100px;
    }
    .white {
      display: inline;
    }
    .blue {
      display: none;
    }
  }
  .menu {
    flex: 1;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;

    .menu-item {
      position: relative;
      width: 100px;
      margin-left: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      color: #000000;

      &:hover {
        span {
          &::after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }

      svg {
        margin-left: 5px;
      }
      span {
        position: relative;
        text-decoration: none;
        color: inherit;
        padding: 10px 0 12px;

        &::after {
          content: " ";
          display: block;
          border-radius: 2px;
          position: absolute;
          height: 2px;
          line-height: 0;
          background-color: #000000;
          width: 100%;
          left: 50%;
          bottom: 0;
          -webkit-transform: translateX(-50%) scaleX(0);
          transform: translateX(-50%) scaleX(0);
          transition: all .3s;
        }
      }
      &.active {
        span {
          // border-bottom: 2px solid #000000;
          &::after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }
    }
  }
}

.nav-menu {
  position: fixed; 
  top: 90px; 
  left: 0px; 
  right: 0px; 
  height: 0;
  width: 100%;
  z-index: 11;

  .sub-menu {
    display: none;
    position: absolute;
    left: 990px;
    top: -10px;
    width: 143px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 0;
    cursor: pointer;

    &.sort {
      width: 96px;
    }

    .sub-menu-item {
      position: relative;
      text-align: center;
      height: 28px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #000001;
      cursor: pointer;
      display: block;
      text-decoration: none;
      margin: 10px 0;

      &.child {
        &::after {
          position: absolute;
          right: 9px;
          top: 10px;
          content: ' ';
          display: inline-block;
          width: 10px;
          height: 10px;
          background-image: url(../assets/images/arrow-right.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      &:hover {
        color: #03289F;
        background: rgba(119, 119, 120, 0.16);

        .sub-menu2 {
          display: inline-block;
        }
      }
    }
  }

  .sub-menu2 {
    display: none;
    position: absolute;
    top: -23px;
    left: 143px;
    width: 132px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 13px 0;
  }
}
.business-contact {
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 65px 80px 80px 150px;
  .company-name {
    margin-bottom: 40px;
    margin-left: -10px;
    img{
      width: 135px;
      height: 75px;
    }
  }
  .company-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    h5 {
      font-weight: normal;
      font-size: 15px;
      color: #000000;
      padding-bottom: 28px;
    }
    p {
      font-weight: normal;
      font-size: 12px;
      color: #605e5e;

      a {
        text-decoration: none;
        color: #605e5e;
      }
    }
    .company-vision {
      flex: 1;
    }
    .company-contact {
      flex: 1;
    }
    .company-join {
      flex: 1;
    }
    .company-social {
      flex: 1;
      position: relative;
      button {
        width: 139px;
        height: 35px;
        border: 1px solid #000000;
        border-radius: 24.5px;
        font-size: 12px;
        color: #605e5e;
        background-color: #fff;
        display: block;
        margin-bottom: 24px;
        &:hover {
          color: blue;
          border: 1px solid blue;
        }
      }
      .weixin-qrcode {
        width: 116px;
        height: 116px;
        position: absolute;
        left: 200px;
        top: 40px;
        display: none;
        img {
          width: 116px;
          height: 116px;
        }
      }
      .linkin-qrcode {
        width: 116px;
        height: 116px;
        position: absolute;
        left: 200px;
        top: 40px;
        display: none;
        img {
          width: 116px;
          height: 116px;
        }
      }
    }
  }
}
.page-footer {
  position: relative;
  width: 100%;
  height: 107px;
  background: #000000;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  a {
    color: #ffffff;
  }
}
.page-zoom {
  position: fixed;
  left: 0;
  top: 0; 
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 12;

  .zoom-mask {
    position: absolute;
    left: 0;
    top: 0; 
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity:0.5;
    z-index: 98;
    transition: all 0.8s;
  }

  .zoom-img {
    position: absolute;
    left: 0;
    top: 0; 
    width: 100vw;
    height: 100vh;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bigimg {
    height:80vh;
    z-index: 99;
    border: 10px solid #fff;
    background-color: #fff;
  }
}

.midnightInner {
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: center;
}

.midnightHeader.default {
  background: none;
  color: #fff;

  img.white {
    display: inline !important;
  }
  img.blue {
    display: none !important;
  }
  .menu-item {
    color: #fff;
    span {
      &::after {
        background-color: #fff;
      }
    }
  }
}

.midnightHeader.white {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  color: #000000!important;
  img.white {
    display: none !important;
  }
  img.blue {
    display: inline !important;
  }
  .menu-item {
    color: #000000!important;

    &.active {
      span {
        &::after {
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
  }
}

.x-container {
  overflow: hidden;
  padding-top: 0px; 
  min-height: 305px;
  background-color: #fff;

  .clause {
    .hr {
      height: 400px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
    }

    .h36 {
      font-size: 64px;
      position: relative;
    }

    .date{
      padding-top: 16px;
      font-size: 21px;
      position: relative;
    }

    .bg-text{
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 144px;
      color: #F7F3F3;
      white-space: nowrap;
    }

    .x-wrap {
      background-color: #F9F9F9;
      padding: 80px 0 120px 0px;

      .list{
        width: 850px;
        position: relative;
        margin: auto;

        .p {
          font-size: 14px;
          line-height: 30px;
        }
  
        .p.p1 {
          text-indent: 28px;
        }
      }

      
    }

    .center {
      text-align: center;
    }
  }
}