.header-menu {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-menu.fixed {
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
}
.header-menu.white {
  color: #fff;
}
.header-menu.white .menu .menu-item {
  color: #fff;
}
.header-menu.white .menu .menu-item.active span::after,
.header-menu.white .menu .menu-item:hover span::after {
  background-color: #fff;
}
.header-menu.black {
  color: #000;
  background-color: #fff;
}
.header-menu.black .menu .menu-item {
  color: #000;
}
.header-menu.black .menu .menu-item.active span::after {
  background-color: #000;
}
.header-menu.black .logo .blue {
  display: inline;
}
.header-menu.black .logo .white {
  display: none;
}
.header-menu .logo {
  width: 212px;
  text-align: right;
}
.header-menu .logo img {
  width: 100px;
}
.header-menu .logo .white {
  display: inline;
}
.header-menu .logo .blue {
  display: none;
}
.header-menu .menu {
  flex: 1;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  padding-right: 60px;
}
.header-menu .menu .menu-item {
  position: relative;
  width: 100px;
  margin-left: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
}
.header-menu .menu .menu-item:hover span::after {
  transform: translateX(-50%) scaleX(1);
}
.header-menu .menu .menu-item svg {
  margin-left: 5px;
}
.header-menu .menu .menu-item span {
  position: relative;
  text-decoration: none;
  color: inherit;
  padding: 10px 0 12px;
}
.header-menu .menu .menu-item span::after {
  content: " ";
  display: block;
  border-radius: 2px;
  position: absolute;
  height: 2px;
  line-height: 0;
  background-color: #000000;
  width: 100%;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%) scaleX(0);
  transform: translateX(-50%) scaleX(0);
  transition: all 0.3s;
}
.header-menu .menu .menu-item.active span::after {
  transform: translateX(-50%) scaleX(1);
}
.nav-menu {
  position: fixed;
  top: 90px;
  left: 0px;
  right: 0px;
  height: 0;
  width: 100%;
  z-index: 11;
}
.nav-menu .sub-menu {
  display: none;
  position: absolute;
  left: 990px;
  top: -10px;
  width: 143px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 8px 0;
  cursor: pointer;
}
.nav-menu .sub-menu.sort {
  width: 96px;
}
.nav-menu .sub-menu .sub-menu-item {
  position: relative;
  text-align: center;
  height: 28px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #000001;
  cursor: pointer;
  display: block;
  text-decoration: none;
  margin: 10px 0;
}
.nav-menu .sub-menu .sub-menu-item.child::after {
  position: absolute;
  right: 9px;
  top: 10px;
  content: ' ';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-image: url(arrow-right.7272767e.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.nav-menu .sub-menu .sub-menu-item:hover {
  color: #03289F;
  background: rgba(119, 119, 120, 0.16);
}
.nav-menu .sub-menu .sub-menu-item:hover .sub-menu2 {
  display: inline-block;
}
.nav-menu .sub-menu2 {
  display: none;
  position: absolute;
  top: -23px;
  left: 143px;
  width: 132px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 13px 0;
}
.business-contact {
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 65px 80px 80px 150px;
}
.business-contact .company-name {
  margin-bottom: 40px;
  margin-left: -10px;
}
.business-contact .company-name img {
  width: 135px;
  height: 75px;
}
.business-contact .company-info {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.business-contact .company-info h5 {
  font-weight: normal;
  font-size: 15px;
  color: #000000;
  padding-bottom: 28px;
}
.business-contact .company-info p {
  font-weight: normal;
  font-size: 12px;
  color: #605e5e;
}
.business-contact .company-info p a {
  text-decoration: none;
  color: #605e5e;
}
.business-contact .company-info .company-vision {
  flex: 1;
}
.business-contact .company-info .company-contact {
  flex: 1;
}
.business-contact .company-info .company-join {
  flex: 1;
}
.business-contact .company-info .company-social {
  flex: 1;
  position: relative;
}
.business-contact .company-info .company-social button {
  width: 139px;
  height: 35px;
  border: 1px solid #000000;
  border-radius: 24.5px;
  font-size: 12px;
  color: #605e5e;
  background-color: #fff;
  display: block;
  margin-bottom: 24px;
}
.business-contact .company-info .company-social button:hover {
  color: blue;
  border: 1px solid blue;
}
.business-contact .company-info .company-social .weixin-qrcode {
  width: 116px;
  height: 116px;
  position: absolute;
  left: 200px;
  top: 40px;
  display: none;
}
.business-contact .company-info .company-social .weixin-qrcode img {
  width: 116px;
  height: 116px;
}
.business-contact .company-info .company-social .linkin-qrcode {
  width: 116px;
  height: 116px;
  position: absolute;
  left: 200px;
  top: 40px;
  display: none;
}
.business-contact .company-info .company-social .linkin-qrcode img {
  width: 116px;
  height: 116px;
}
.page-footer {
  position: relative;
  width: 100%;
  height: 107px;
  background: #000000;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.page-footer a {
  color: #ffffff;
}
.page-zoom {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 12;
}
.page-zoom .zoom-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 98;
  transition: all 0.8s;
}
.page-zoom .zoom-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-zoom .bigimg {
  height: 80vh;
  z-index: 99;
  border: 10px solid #fff;
  background-color: #fff;
}
.midnightInner {
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: center;
}
.midnightHeader.default {
  background: none;
  color: #fff;
}
.midnightHeader.default img.white {
  display: inline !important;
}
.midnightHeader.default img.blue {
  display: none !important;
}
.midnightHeader.default .menu-item {
  color: #fff;
}
.midnightHeader.default .menu-item span::after {
  background-color: #fff;
}
.midnightHeader.white {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  color: #000000 !important;
}
.midnightHeader.white img.white {
  display: none !important;
}
.midnightHeader.white img.blue {
  display: inline !important;
}
.midnightHeader.white .menu-item {
  color: #000000 !important;
}
.midnightHeader.white .menu-item.active span::after {
  transform: translateX(-50%) scaleX(1);
}
.x-container {
  overflow: hidden;
  padding-top: 0px;
  min-height: 305px;
  background-color: #fff;
}
.x-container .clause .hr {
  height: 400px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.x-container .clause .h36 {
  font-size: 64px;
  position: relative;
}
.x-container .clause .date {
  padding-top: 16px;
  font-size: 21px;
  position: relative;
}
.x-container .clause .bg-text {
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 144px;
  color: #F7F3F3;
  white-space: nowrap;
}
.x-container .clause .x-wrap {
  background-color: #F9F9F9;
  padding: 80px 0 120px 0px;
}
.x-container .clause .x-wrap .list {
  width: 850px;
  position: relative;
  margin: auto;
}
.x-container .clause .x-wrap .list .p {
  font-size: 14px;
  line-height: 30px;
}
.x-container .clause .x-wrap .list .p.p1 {
  text-indent: 28px;
}
.x-container .clause .center {
  text-align: center;
}

/*# sourceMappingURL=aboutus.59e4b921.css.map */
